import { Typography, Box, Container } from "@mui/material";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <> 
    <Container maxWidth="lg"> 
     <Typography variant="body1" fontWeight={600} align='left' gutterBottom> Disclosures </Typography>
    {
      location.pathname.includes('connect-with-us') && 
      <> 
      <Typography variant="body2" color="#555" align='left' gutterBottom> By providing us with a telephone number for a cellular phone or other wireless device, including a number that you later convert to a cellular number, you are expressly consenting to receiving communications - including but not limited to prerecorded or artificial voice message calls, text messages, and calls made by an automatic telephone dialing system - from us and our affiliates and agents at that number. This express consent applies to each such telephone number that you provide to us now or in the future and permits such calls for non-marketing purposes. Calls and messages may incur access fees from your cellular provider.</Typography>
      <Typography variant="body2" color="#555" align='left' gutterBottom> By providing your email address, you are consenting to receive marketing messages from U.S. Bank. If you prefer not to receive marketing email from us, you can opt-out of all marketing emails by adjusting your preferences at any time.</Typography>
      </>
    }
    
    <Typography variant="body2" color="#555" align='left' gutterBottom>All trademarks, product names, logos, and brands are property of their respective owners and are used for identification purposes only. Use of these trademarks, names, logos, and brands or the listing of a company on this page does not imply endorsement of the company, or its products or services by U.S. Bank.  Customers should perform their own due diligence on the appropriateness of any company’s products and services for use by the customer.</Typography>
    <Typography variant="body2" color="#555" align='left' gutterBottom>RTP® is a registered trademark of The Clearing House Payments Company L.L.C. Zelle and the Zelle related marks are wholly owned by Early Warning Services, LLC and are used herin under license.</Typography>
    <Typography variant="body2" color="#555" align='left' gutterBottom mb={3}>Deposit products are offered by U.S. Bank National Association. Member FDIC.</Typography>
    </Container>
    <Box bgcolor='primary.darker' py={2}>
        <Container maxWidth="lg"> 
       
        <Typography variant="body2" color='#fff' align='right'> © {new Date().getFullYear()} U.S. Bank</Typography>
      </Container>
    </Box>
    </>
  );
};
export default Footer;
